import React from "react";
import { useState, useCallback } from "react";
import NavBar from "../logged_out/components/navigation/NavBar";
import Footer from "../logged_out/components/footer/Footer";

const Privacy = () => {
  //manual styling
  const background = {
    backgroundColor: "#2f64b4",
    zIndex: 1,
  };
  const wrapper = {
    background: "#ffffff",
    width: "90vw",
    margin: "2rem auto 0 auto",
    padding: "2rem",
    zIndex: 2,
  };

  //responsive props
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const handleMobileDrawerOpen = useCallback(() => {
    setIsMobileDrawerOpen(true);
  }, [setIsMobileDrawerOpen]);

  const handleMobileDrawerClose = useCallback(() => {
    setIsMobileDrawerOpen(false);
  }, [setIsMobileDrawerOpen]);

  return (
    <>
      <NavBar
        mobileDrawerOpen={isMobileDrawerOpen}
        handleMobileDrawerOpen={handleMobileDrawerOpen}
        handleMobileDrawerClose={handleMobileDrawerClose}
      />
      <div style={background}>
        <div style={wrapper}>
        <h1 style={{ textAlign: "center" }}>Pemberitahuan Privasi Aplikasi Perigigi</h1>
    <p><em>Terakhir diperbarui: 12 Desember 2023</em></p>

    <p>Selamat datang di Aplikasi Perigigi, layanan Rekam Medis Elektronik dari PT Aplikasi Kesehatan Bangsa. Pemberitahuan privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi data pribadi pasien yang diakses melalui Aplikasi Perigigi. Kami berkomitmen untuk menjaga kerahasiaan dan keamanan data yang Anda berikan kepada kami.</p>

    <h2>1. Jenis Data yang Kami Kumpulkan</h2>
    <p>1.1 Data Medis Pasien:</p>
    <ul>
        <li>Riwayat kesehatan.</li>
        <li>Hasil pemeriksaan dan diagnosa oleh dokter gigi.</li>
        <li>Data terkait perawatan dan pengobatan.</li>
    </ul>

    <p>1.2 Data Identitas, Kontak, dan Pembayaran Pasien:</p>
    <ul>
        <li>Nama lengkap.</li>
        <li>Jenis Kelamin.</li>
        <li>Tanggal Lahir.</li>
        <li>Nomor identifikasi (KTP / Kitas / Paspor).</li>
        <li>Alamat rumah.</li>
        <li>Nomor handphone.</li>
        <li>Pekerjaan.</li>
        <li>Catatan lain mengenai pasien.</li>
        <li>Data mengenai pembayaran asuransi.</li>
    </ul>

    <p>1.3 Data Identitas dan Profesi Dokter Gigi:</p>
    <ul>
        <li>Nama lengkap.</li>
        <li>Jenis Kelamin.</li>
        <li>Tanggal Lahir.</li>
        <li>Nomor identifikasi (KTP).</li>
        <li>Dokumen Pendukung Profesi Kedokteran Gigi.</li>
        <li>Dokumen Perijinan Praktek.</li>
        <li>Nomor handphone.</li>
        <li>Email.</li>
    </ul>

    <h2>2. Penggunaan Data</h2>
    <p>2.1 Keperluan Medis:</p>
    <ul>
        <li>Data medis dan identifikasi pasien digunakan untuk memberikan perawatan gigi yang optimal.</li>
        <li>Memfasilitasi komunikasi antara dokter gigi dan pasien.</li>
    </ul>

    <p>2.2 Keperluan Kontak:</p>
    <ul>
        <li>Menyimpan data kontak pasien untuk keperluan pemberitahuan dan koordinasi perawatan.</li>
    </ul>

    <p>2.3 Keperluan Pembayaran dan Asuransi:</p>
    <ul>
        <li>Menyimpan data pembayaran dan asuransi pasien untuk keperluan pembayaran layanan medis dan klaim asuransi.</li>
    </ul>

    <h2>3. Penyimpanan Data</h2>
    <p>3.1 Data Pribadi Anda hanya akan disimpan selama diperlukan untuk memenuhi tujuan dari pengumpulannya, selama masa retensi atau selama penyimpanan tersebut diperlukan atau diperbolehkan oleh Peraturan Perundang-undangan yang Berlaku. Kami akan berhenti menyimpan Data Pribadi, atau menghapus maksud dari dikaitkannya Data Pribadi tersebut dengan Anda sebagai individu, segera setelah dianggap bahwa tujuan pengumpulan Data Pribadi tersebut tidak lagi dibutuhkan dengan menyimpan Data Pribadi, terdapat permintaan dari Anda untuk melakukan penghapusan akun Anda, dan penyimpanan tidak lagi diperlukan untuk tujuan bisnis atau secara hukum.</p>
    <p>3.2 Kami akan menghapus dan/atau menganonimkan Data Pribadi Pengguna yang ada di bawah kendali Kami apabila (i) Data Pribadi Pengguna tidak lagi diperlukan untuk memenuhi tujuan dari pengumpulannya; (ii) berakhirnya masa retensi dan (iii) penyimpanan tidak lagi diperlukan untuk tujuan kepatuhan menurut ketentuan Peraturan Perundang-undangan yang Berlaku.</p>
    <p>3.3 Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Data Pribadi Anda disimpan atau dikuasai oleh pihak lain termasuk institusi pemerintah dengan cara tertentu. Dalam hal Kami membagikan Data Pribadi Anda kepada institusi pemerintah yang berwenang dan/atau institusi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama dengan Kami, Anda menyetujui dan mengakui bahwa penyimpanan Data Pribadi Anda oleh institusi terkait akan mengikuti kebijakan penyimpanan data masing-masing institusi tersebut.</p>

    <p>3.4 Seluruh aplikasi, data, dan backup disimpan di dalam wilayah Indonesia untuk memastikan kepatuhan dengan regulasi dan hukum yang berlaku.</p>

    <h2>4. Keamanan Data</h2>
    <p>4.1 Kami akan mengambil tindakan hukum, organisasi, dan teknis yang wajar untuk memastikan bahwa Data Pribadi Anda dilindungi. Hal ini termasuk langkah-langkah untuk mencegah Data Pribadi hilang, atau digunakan atau diakses dengan cara yang tidak sah. Kami membatasi akses ke Data Pribadi Anda hanya untuk karyawan kami berdasarkan kebutuhan untuk mengetahui. Mereka yang memproses Data Pribadi Anda hanya akan melakukannya dengan cara yang diizinkan dan diwajibkan untuk menjaga kerahasiaan informasi Anda. Namun demikian, harap dipahami bahwa transmisi informasi melalui internet tidak sepenuhnya aman. Meskipun kami akan melakukan yang terbaik untuk melindungi Data Pribadi Anda, kami tidak dapat menjamin keamanan Data Pribadi Anda yang dikirimkan melalui sarana daring apa pun, oleh karena itu, setiap transmisi tetap menjadi risiko Anda sendiri.</p>
    <p>4.2 Selain karyawan kami, hanya dokter gigi dan staf terkait yang memiliki hak akses ke informasi medis pasien.</p>

    <h2>5. Pembagian Data</h2>
    <p>5.1 Informasi medis pasien tidak akan dibagikan ke pihak luar, kecuali dengan persetujuan tertulis dari pasien atau jika diwajibkan oleh hukum.</p>
    <p>5.2 Pengguna Aplikasi Perigigi terbatas pada dokter gigi dan staf mereka, dan tidak ada akses langsung yang diberikan kepada pasien.</p>

    <h2>6. Akses dan Perubahan Data</h2>
    <p>6.1 Pasien dapat meminta akses atau perubahan pada informasi pribadi mereka dengan menghubungi dokter gigi yang menangani.</p>
    <p>6.2 Kami akan merespons permintaan ini sesuai dengan prosedur yang berlaku dan kebijakan internal kami.</p>

    <h2>7. Verifikasi Data</h2>
    <p>7.1 PT Aplikasi Kesehatan Bangsa berhak memverifikasi data yang diberikan oleh dokter kepada kami dengan pihak yang berwajib, jika diperlukan untuk memastikan keakuratan dan keandalan data.</p>

    <h2>8. Kontak dan Dukungan</h2>
    <p>8.1 Untuk permintaan data atau permintaan perubahan data, harap kontak dokter gigi yang menangani Anda.</p>
    <p>8.2 Pertanyaan lain mengenai pemberitahuan privasi atau teknis Aplikasi Perigigi dapat diarahkan ke tim dukungan kami melalui info@perigigi.com.</p>

    <h2>9. Pembaruan Pemberitahuan Privasi</h2>
    <p>9.1 Pemberitahuan Privasi ini dapat diperbarui dari waktu ke waktu tanpa pemberitahuan sebelumnya. Setiap perubahan akan segera diberitahukan melalui saluran komunikasi yang sesuai.</p>
    <br />
    <p>Dengan menggunakan Aplikasi Perigigi, Anda setuju dengan Pemberitahuan Privasi ini. Jika Anda tidak setuju dengan Pemberitahuan Privasi kami, kami sarankan untuk tidak menggunakan layanan kami.</p>
    <p>Terima kasih atas kepercayaan Anda kepada PT Aplikasi Kesehatan Bangsa dan Aplikasi Perigigi.</p>
    <p>Hormat kami,</p> 
    <p>PT Aplikasi Kesehatan Bangsa</p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
