import React from "react";
import NavBar from "../logged_out/components/navigation/NavBar";
import Footer from "../logged_out/components/footer/Footer";
import { useState, useCallback } from "react";

const Terms = () => {
  const background = {
    backgroundColor: "#2f64b4",
  };
  const wrapper = {
    background: "#ffffff",
    width: "90vw",
    margin: "2rem auto 0 auto",
    padding: "2rem",
  };

  //responsive props
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const handleMobileDrawerOpen = useCallback(() => {
    setIsMobileDrawerOpen(true);
  }, [setIsMobileDrawerOpen]);

  const handleMobileDrawerClose = useCallback(() => {
    setIsMobileDrawerOpen(false);
  }, [setIsMobileDrawerOpen]);

  return (
    <>
      <NavBar
        mobileDrawerOpen={isMobileDrawerOpen}
        handleMobileDrawerOpen={handleMobileDrawerOpen}
        handleMobileDrawerClose={handleMobileDrawerClose}
      />
      <div style={background}>
        <div style={wrapper}>
    <h1 style={{ textAlign: "center" }}>Syarat dan Ketentuan Aplikasi Perigigi</h1>
    <p><em>Terakhir diperbarui: 12 Desember 2023</em></p>

    <p>Selamat datang di Aplikasi Perigigi, layanan Rekam Medis Elektronik dari PT Aplikasi Kesehatan Bangsa. Laman ini berisi Syarat dan Ketentuan penggunaan Aplikasi Perigigi. Bacalah dengan teliti, karena penggunaan aplikasi ini menandakan persetujuan Anda terhadap ketentuan-ketentuan yang berlaku. Kami berkomitmen untuk memberikan pelayanan terbaik. </p>
          
    <h2>1. Definisi dan Interpretasi</h2>
    <p>1.1 Aplikasi: Merujuk pada Aplikasi Perigigi - Rekam Medis Elektronik yang disediakan oleh PT Aplikasi Kesehatan Bangsa.</p>
    <p>1.2 Dokter Gigi: Merujuk pada individu yang memiliki kualifikasi dan lisensi yang sah untuk praktek kedokteran gigi dan menggunakan Aplikasi Perigigi untuk menyimpan dan mengakses rekam medis pasien.</p>
    <p>1.3 Pemilik Aplikasi: Merujuk pada PT Aplikasi Kesehatan Bangsa, yang merupakan pemilik dan penyedia Aplikasi Perigigi.</p>

    <h2>2. Penggunaan Aplikasi</h2>
    <p>2.1 Penggunaan Aplikasi Perigigi dibatasi pada tujuan medis dan kesehatan gigi.</p>
    <p>2.2 Dokter Gigi wajib menyediakan informasi yang benar, akurat, dan lengkap saat menggunakan Aplikasi Perigigi.</p>

    <h2>3. Keamanan dan Privasi</h2>
    <p>3.1 PT Aplikasi Kesehatan Bangsa berkomitmen untuk menjaga keamanan dan privasi data pasien yang tersimpan dalam Aplikasi Perigigi.</p>
    <p>3.2 Dokter Gigi wajib menjaga kerahasiaan informasi pasien dan tidak boleh memberikan akses ke Aplikasi Perigigi kepada pihak ketiga tanpa izin tertulis dari pasien.</p>

    <h2>4. Kewajiban Dokter Gigi</h2>
    <p>4.1 Dokter Gigi bertanggung jawab atas semua aktivitas yang terjadi pada akunnya dan wajib melaporkan setiap penggunaan yang tidak sah segera kepada PT Aplikasi Kesehatan Bangsa.</p>
    <p>4.2 Dokter Gigi wajib menggunakan Aplikasi Perigigi sesuai dengan peraturan dan etika profesi kedokteran gigi yang berlaku.</p>

    <h2>5. Pembayaran dan Langganan</h2>
    <p>5.1 Dokter Gigi yang menggunakan Aplikasi Perigigi harus membayar biaya langganan sesuai dengan ketentuan yang berlaku.</p>
    <p>5.2 PT Aplikasi Kesehatan Bangsa berhak mengubah biaya langganan dan/atau menawarkan pilihan pembayaran tambahan setiap saat.</p>

    <h2>6. Pembaruan dan Perbaikan</h2>
    <p>6.1 PT Aplikasi Kesehatan Bangsa berhak melakukan pembaruan dan perbaikan pada Aplikasi Perigigi secara berkala.</p>
    <p>6.2 Dokter Gigi diharapkan untuk selalu menggunakan versi terbaru Aplikasi Perigigi untuk memastikan keamanan dan fungsionalitas yang optimal.</p>

    <h2>7. Pembatalan dan Pengembalian Dana</h2>
    <p>7.1 Dokter Gigi dapat membatalkan langganan Aplikasi Perigigi kapan saja. Dalam hal pembatalan, PT Aplikasi Kesehatan Bangsa berhak melakukan pengembalian dana sesuai dengan kebijakannya, dengan mempertimbangkan periode penggunaan yang telah berlalu.</p>
    <p>7.2 PT Aplikasi Kesehatan Bangsa berhak menentukan persyaratan dan prosedur terkait dengan pengembalian dana, termasuk namun tidak terbatas pada potongan biaya administrasi atau penalti yang mungkin diterapkan.</p>
    <p>7.3 Dokter Gigi memahami dan menyetujui bahwa pengembalian dana dapat memerlukan waktu tertentu untuk diproses, dan PT Aplikasi Kesehatan Bangsa tidak bertanggung jawab atas keterlambatan dalam proses ini.</p>

    <h2>8. Perubahan Syarat dan Ketentuan</h2>
    <p>8.1 PT Aplikasi Kesehatan Bangsa berhak mengubah Syarat dan Ketentuan ini sesuai dengan kebijakannya. Perubahan tersebut akan diinformasikan kepada Dokter Gigi melalui platform Aplikasi Perigigi atau melalui kontak yang terdaftar.</p>

    <h2>9. Hukum yang Berlaku</h2>
    <p>9.1 Syarat dan Ketentuan ini tunduk pada hukum yang berlaku di wilayah Republik Indonesia.</p>

    <h2>10. Hak Milik Intelektual</h2>
    <p>10.1 Dokter Gigi dengan ini menyatakan dan menjamin bahwa dia telah membaca, memahami, dan menyetujui sepenuhnya seluruh Syarat dan Ketentuan yang tercantum dalam dokumen ini.</p>
    <p>10.2 Dokter Gigi setuju bahwa semua hak milik intelektual yang terkait dengan Aplikasi Perigigi tetap menjadi milik eksklusif dari PT Aplikasi Kesehatan Bangsa.</p>
    <p>10.3 Dokter Gigi dilarang untuk melakukan reproduksi, distribusi, atau modifikasi Aplikasi Perigigi atau bagian-bagiannya tanpa izin tertulis dari PT Aplikasi Kesehatan Bangsa.</p>
    <p>10.4 Dokter Gigi setuju untuk tidak mengklaim atau melakukan tindakan yang dapat merugikan hak milik intelektual PT Aplikasi Kesehatan Bangsa terkait dengan Aplikasi Perigigi.</p>
    <p>10.5 Dokter Gigi memberikan izin kepada PT Aplikasi Kesehatan Bangsa untuk menggunakan informasi dan data yang dihasilkan oleh Aplikasi Perigigi untuk pengembangan, analisis, dan perbaikan Aplikasi Perigigi serta untuk keperluan riset dan pengembangan.</p>
    <p>10.6 Dokter Gigi setuju bahwa PT Aplikasi Kesehatan Bangsa dapat menggunakan nama dan logo dokter sebagai bagian dari promosi atau materi pemasaran terkait dengan Aplikasi Perigigi, dengan syarat memberikan pemberitahuan dan mendapatkan izin tertulis terlebih dahulu dari Dokter Gigi.</p>
    
    <h2>11. Pembaruan Syarat dan Ketentuan</h2>
    <p>11.1 Syarat dan Ketentuan ini dapat diperbarui dari waktu ke waktu tanpa pemberitahuan sebelumnya. Setiap perubahan akan segera diberitahukan melalui saluran komunikasi yang sesuai.</p>
    <br />
    <p>Dengan menggunakan Aplikasi Perigigi, Anda setuju dengan Syarat dan Ketentuan ini. Jika Anda tidak setuju dengan Syarat dan Ketentuan kami, kami sarankan untuk tidak menggunakan layanan kami.</p>
    <p>Terima kasih atas kepercayaan Anda kepada PT Aplikasi Kesehatan Bangsa dan Aplikasi Perigigi.</p>
    <p>Hormat kami,</p>
    <p>PT Aplikasi Kesehatan Bangsa</p>
        </div>
      </div>
    </>
  );
};

export default Terms;
