import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Hidden,
  TextField,
  Button,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import WaveBorder from "../../../shared/components/WaveBorder";
import ColoredButton from "../../../shared/components/ColoredButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileStoreButton from "react-mobile-store-button";
import { render } from "react-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import googleIcon from '../../../assets/Google-play.png'
import appleIcon from '../../../assets/Apple-logo.png'
const styles = (theme) => ({
  footerInner: {
    backgroundColor: theme.palette.common.darkBlack,
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(10),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(10),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  brandText: {
    fontFamily: "'Baloo Bhaijaan', cursive",
    fontWeight: 400,
    color: theme.palette.common.white,
  },
  footerLinks: {
    marginTop: theme.spacing(2.5),
    marginBot: theme.spacing(1.5),
    color: theme.palette.common.white,
  },
  infoIcon: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: "#33383b !important",
  },
  alink: {
    textDecoration: "none !important",
  },
  socialIcon: {
    fill: theme.palette.common.white,
    backgroundColor: "#33383b",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  link: {
    cursor: "Pointer",
    color: theme.palette.common.white,
    transition: theme.transitions.create(["color"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  whiteBg: {
    backgroundColor: theme.palette.common.white,
  },
});

const infos = [
  {
    icon: <PhoneIcon href="" />,
    description: "+62 88973460110",
    link: "whatsapp://send?phone=+6288973460110&text='Hello Perigigi Support!'",
  },
  {
    icon: <MailIcon />,
    description: "info@perigigi.com",
    link: "mailto:info@perigigi.com",
  },
];

function Footer(props) {
  const { classes, theme } = props;
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <footer>
      <WaveBorder
        upperColor="#FFFFFF"
        lowerColor={theme.palette.common.darkBlack}
        animationNegativeDelay={4}
      />
      <div className={classes.footerInner}>
        <Grid container spacing={isWidthUpMd ? 10 : 5}>
          <Grid item xs={12} md={6} lg={4}>
            <Box display="" mb={1}>
              <a href="#" class="link-wrapper">
                <div class="img-wrapper">
                  <img
                    src={googleIcon}
                    alt="google icon"
                  />
                </div>
                <div class="content-wrapper">
                  <h6 className="m-0">Android app on</h6>
                  <p className="m-0">Google Play</p>
                </div>
              </a>
            </Box>
            <Box display="" mb={3}>
              <a href="#" class="link-wrapper">
                <div class="img-wrapper">
                  <img
                    src={appleIcon}
                    alt="google icon"
                  />
                </div>
                <div class="content-wrapper">
                  <h6 className="m-0">iOS App on</h6>
                  <p className="m-0">App Store</p>
                </div>
              </a>
            </Box>

          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Box>
              <div>
                {infos.map((info, index) => (
                  <a className={classes.alink} href={info.link}>
                    <Box display="flex" mb={1} key={index}>
                      <Box mr={2}>
                        <IconButton
                          className={classes.infoIcon}
                          tabIndex={-1}
                          disabled
                          size="large"
                        >
                          {info.icon}
                        </IconButton>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography variant="h6" className="text-white">
                          {info.description}
                        </Typography>
                      </Box>
                    </Box>
                  </a>
                ))}
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h6" paragraph className="text-white">
              Tentang Perigigi
            </Typography>
            <Typography style={{ color: "#8f9296" }} paragraph>
              Perigigi adalah perusahaan startup yang percaya bahwa kesehatan
              gigi adalah hak mendasar bagi seluruh warga Indonesia. Misi kami
              adalah memberdayakan dokter gigi Indonesia untuk bisa menjadi
              lebih:
              <ul>
                <li>Produktif</li>
                <li>Dekat dengan pasien</li>
                <li>Terorganisir</li>
              </ul>
            </Typography>
            <Typography style={{ color: "#8f9296" }}>
              &copy; 2023 PT Aplikasi Kesehatan Bangsa. Perigigi - Aplikasi Super Dokter Gigi Indonesia
            </Typography>
            {/*<Box display="flex">
              {socialIcons.map((socialIcon, index) => (
                <Box key={index} mr={index !== socialIcons.length - 1 ? 1 : 0}>
                  <IconButton
                    aria-label={socialIcon.label}
                    className={classes.socialIcon}
                    href={socialIcon.href}
                    size="large"
                  >
                    {socialIcon.icon}
                  </IconButton>
                </Box>
              ))}
              </Box>*/}
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Footer);
